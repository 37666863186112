import { render, staticRenderFns } from "./modalDetalleProd.vue?vue&type=template&id=3cb6211c&scoped=true"
import script from "./modalDetalleProd.vue?vue&type=script&lang=js"
export * from "./modalDetalleProd.vue?vue&type=script&lang=js"
import style0 from "./modalDetalleProd.vue?vue&type=style&index=0&id=3cb6211c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cb6211c",
  null
  
)

export default component.exports